import { buildLoginUrl } from '@zorba-shared/core/Services/urlBuilderService';

export const getHeaderLings = (path) => [
  ...(path === '/pm' ? [{
    title: 'Property Managers',
    href: '/pm',
    isActive: (pathname) => pathname === '/pm',
  }] : []),
  // {
  //   title: 'Data',
  //   href: '/data',
  //   isActive: (pathname) => pathname == '/data',
  // },  ,
  {
    title: 'Owners',
    href: '/',
    isActive: (pathname) => pathname == '/',
  },
  {
    title: 'Agents',
    href: '/agents',
    isActive: (pathname) => pathname == '/agents',
  },
  {
    title: 'Investors',
    href: '/investors',
    isActive: (pathname) => pathname == '/investors',
  },

  {
    title: 'Blog',
    href: 'https://blog.getzorba.com',
    isActive: (pathname) => false,
  },  
  {
    title: 'FAQ',
    href: '/faq',
    isActive: (pathname) => pathname.includes('/faq'),
  },
  {
    title: 'Our story',
    href: '/about-us',
    isActive: (pathname) => pathname.includes('/about-us'),
  },
];
